export var UTVPlayerCueType;
(function (UTVPlayerCueType) {
    UTVPlayerCueType["AD"] = "AD";
})(UTVPlayerCueType || (UTVPlayerCueType = {}));
// @TODO Rename StreamType - remove stream from it and add a new prefix
export var UTVPlayerStreamType;
(function (UTVPlayerStreamType) {
    UTVPlayerStreamType["LIVE"] = "LIVE";
    UTVPlayerStreamType["REPLAY"] = "REPLAY";
    UTVPlayerStreamType["RECORDING"] = "RECORDING";
    UTVPlayerStreamType["TIMESHIFT"] = "TIMESHIFT";
    UTVPlayerStreamType["VOD_MOVIE"] = "VOD_MOVIE";
    UTVPlayerStreamType["VOD_EPISODE"] = "VOD_EPISODE";
})(UTVPlayerStreamType || (UTVPlayerStreamType = {}));
export var UTVPlayerErrorType;
(function (UTVPlayerErrorType) {
    UTVPlayerErrorType["PIN_ERROR"] = "PIN_ERROR";
    UTVPlayerErrorType["STREAM_CANNOT_PLAY"] = "STREAM_CANNOT_PLAY";
    UTVPlayerErrorType["FORBIDDEN_ACTION"] = "FORBIDDEN_ACTION";
    UTVPlayerErrorType["UNKNOWN"] = "UNKNOWN";
})(UTVPlayerErrorType || (UTVPlayerErrorType = {}));
export var UTVPlayerEvents;
(function (UTVPlayerEvents) {
    UTVPlayerEvents["PAUSED"] = "PAUSED";
    UTVPlayerEvents["STOPPED"] = "STOPPED";
    UTVPlayerEvents["PLAYING"] = "PLAYING";
    UTVPlayerEvents["PLAY_REQUESTED"] = "PLAY_REQUESTED";
    UTVPlayerEvents["CLOSE_TO_END"] = "CLOSE_TO_END";
    UTVPlayerEvents["ERROR"] = "ERROR";
    UTVPlayerEvents["INITIALIZED"] = "INITIALIZED";
    UTVPlayerEvents["STATE_UPDATED"] = "STATE_UPDATED";
    UTVPlayerEvents["TRACK_UPDATED"] = "TRACK_UPDATED";
    UTVPlayerEvents["POSITION_SAVED"] = "POSITION_SAVED";
    UTVPlayerEvents["QUALITY_RESTRICTED"] = "QUALITY_RESTRICTED";
    // @TODO Placeholder for undefined events
    UTVPlayerEvents["UNKNOWN"] = "UNKNOWN";
})(UTVPlayerEvents || (UTVPlayerEvents = {}));
