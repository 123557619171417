import { AudioFormat } from '../player/enums/audio-format';
import { SubtitlesCodec, } from '../capability';
export const watchParamsFromStreamType = (streamType) => ({
    stream_type: streamType,
    https_watch_urls: true,
});
export const watchParamsFromPlayOptions = (playOptions) => {
    const params = {};
    if (playOptions?.pin) {
        params.youth_protection_pin = playOptions.pin;
    }
    if (playOptions?.preferredAudioFormat === AudioFormat.DOLBY) {
        params.enable_eac3 = true;
    }
    return params;
};
export const watchParamsFromCapability = (capability) => {
    if (capability?.subtitlesCodec) {
        return {
            vtt: SubtitlesCodec.VTT === capability.subtitlesCodec,
            ttml: SubtitlesCodec.TTML === capability.subtitlesCodec,
        };
    }
    return {};
};
export const mapHdcpVersionToType = (version) => {
    if (!version) {
        return null;
    }
    if (version.toLowerCase() === 'unprotected') {
        return 'Unprotected';
    }
    if (Number(version) >= 1 && Number(version) < 2) {
        return 0;
    }
    if (Number(version) >= 2) {
        return 1;
    }
    return null;
};
export const watchParamsFromAdapterCapability = (capability) => {
    const params = {};
    if (capability.hdcp) {
        const hdcpType = mapHdcpVersionToType(capability.hdcp.connectedVersion);
        if (hdcpType !== null) {
            params.max_hdcp_type = hdcpType;
        }
    }
    return params;
};
/**
 * Picks highest rate from watch urls list
 * Remark: maxrate 0 means unlimited
 */
export const pickHighestRate = (watchUrls) => {
    if (watchUrls.length === 0) {
        return null;
    }
    const sort = (infoA, infoB) => {
        const maxRateA = infoA.maxrate || Number.POSITIVE_INFINITY;
        const maxRateB = infoB.maxrate || Number.POSITIVE_INFINITY;
        // order descending
        return maxRateB - maxRateA;
    };
    return watchUrls.slice().sort(sort)[0] ?? null;
};
