import { Player_AudioFormat, } from '@zattoo/taf-protobuf/protobuf/player';
import { PlayerOutput_MediaType, PlayerOutput_TimeshiftAvailability, PlayerOutput_PlaybackStateChanged_State, } from '@zattoo/taf-protobuf/protobuf/player_output';
import { MediaType, PlaybackState, AudioFormat, } from '@zattoo/playback-sdk';
import { TimeshiftStatus } from '@zattoo/zapi/lib/enums';
export const errorPlayerNotCreated = new Error('Player not created');
export const convertPreferredAudioLanguage = (preferredAudioLanguage) => {
    switch (preferredAudioLanguage) {
        case Player_AudioFormat.DOLBY:
            return AudioFormat.DOLBY;
        case Player_AudioFormat.STEREO:
            return AudioFormat.STEREO;
        default:
            return undefined;
    }
};
export const playOptions = (options) => {
    return {
        preferredAudioLanguage: options?.preferredAudioTrack ?? undefined,
        preferredSubtitlesLanguage: options?.preferredSubtitlesTrack ?? undefined,
        drmRequired: options?.drmRequired ?? undefined,
        pin: options?.pin ?? undefined,
        startupPosition: options?.startupPosition ?? undefined,
        adsUrl: options?.adsUrl ?? undefined,
        fairPlayCertificateUrl: options?.fairPlayCertificateUrl,
        preferredAudioFormat: convertPreferredAudioLanguage(options?.preferredAudioFormat),
    };
};
export const playerOptions = (options) => {
    return {
        host: options.host ?? '',
        appId: options.appId ?? 0,
        appVersion: options.appVersion ?? '0',
        publicId: options.publicId ?? 'taf',
        stepBackwardDuration: options.stepBackwardDuration ?? 10,
        stepForwardDuration: options.stepForwardDuration ?? 10,
        muted: options.muted,
    };
};
export const convertMediaTrack = (mediaTrack) => {
    return {
        id: mediaTrack.id,
        codec: mediaTrack.codec,
        locale: mediaTrack.locale,
        describesMusicAndSound: mediaTrack.describesMusicAndSound,
        transcribesSpokenDialog: mediaTrack.transcribesSpokenDialog,
    };
};
export const convertMediaType = (mediaType) => {
    switch (mediaType) {
        case MediaType.LIVE:
            return PlayerOutput_MediaType.LIVE;
        case MediaType.VOD:
            return PlayerOutput_MediaType.VOD;
        case MediaType.REPLAY:
            return PlayerOutput_MediaType.REPLAY;
        case MediaType.RECORDING:
            return PlayerOutput_MediaType.RECORDING;
        case MediaType.REGISTERED_TIMESHIFT:
            return PlayerOutput_MediaType.REGISTERED_TIMESHIFT;
        case MediaType.URL:
            return PlayerOutput_MediaType.URL;
        default:
            throw new Error(`Unknown media type ${mediaType}`);
    }
};
export const convertTimeshiftStatus = (sdkTimeshiftStatus) => {
    switch (sdkTimeshiftStatus) {
        case TimeshiftStatus.AVAILABLE:
            return PlayerOutput_TimeshiftAvailability.AVAILABLE;
        case TimeshiftStatus.ELIGIBLE:
            return PlayerOutput_TimeshiftAvailability.ELIGIBLE;
        case TimeshiftStatus.SUBSCRIBABLE:
            return PlayerOutput_TimeshiftAvailability.SUBSCRIBABLE;
        case TimeshiftStatus.UNAVAILABLE:
            return PlayerOutput_TimeshiftAvailability.UNAVAILABLE;
        default:
            return undefined;
    }
};
// TODO: https://zattoo2.atlassian.net/browse/PLY-1484
export const convertMedia = (media) => {
    return {
        url: media.url,
        licenseUrl: media.licenseUrl ?? undefined,
        prePadding: media.prePadding,
        postPadding: media.postPadding,
        mediaType: convertMediaType(media.mediaType),
        dynamic: media.dynamic,
        timeshiftAvailability: convertTimeshiftStatus(media.timeshiftAvailability ?? undefined),
    };
};
export const convertPlaybackState = (state) => {
    switch (state) {
        case PlaybackState.PLAYING:
            return PlayerOutput_PlaybackStateChanged_State.PLAYING;
        case PlaybackState.BUFFERING:
            return PlayerOutput_PlaybackStateChanged_State.BUFFERING;
        case PlaybackState.PAUSED:
            return PlayerOutput_PlaybackStateChanged_State.PAUSED;
        case PlaybackState.STOPPED:
            return PlayerOutput_PlaybackStateChanged_State.STOPPED;
        default:
            throw new Error(`Unknown state ${state}`);
    }
};
