import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState, } from 'react';
import { throttle } from 'lodash';
import { isZAPIError, ErrorCode as ZAPIErrorCodes, } from '@zattoo/zapi/lib/helpers/error';
import { Teasable } from '@zattoo/zapi/lib/editorial/enums';
import { TimeshiftStatus } from '@zattoo/zapi/lib/stream/enums';
import { getLanguageNames, updateBroadcastPosition, updateVODStatus, } from '@zattoo/zapi';
import { ChannelLogger, ConsoleLogger, ErrorCode, EventKey, isPlaybackError, MediaType, PlaybackState, } from '@zattoo/playback-sdk';
import { styles } from './styles';
import { createPlayer, PlayerView, } from '../platforms';
import { UTVPlayerEvents, UTVPlayerStreamType, UTVPlayerErrorType, } from './interfaces';
import { initialProgramInfo, playerInitialState, } from './states';
import { getISOLanguageName, setMediaTrackById, updateProgram, } from './utils';
import { init as initPlaybackSdk } from '../index';
const mapMediaTypeToStreamType = (mediaType, requestedStreamType) => {
    switch (mediaType) {
        case MediaType.LIVE:
            return UTVPlayerStreamType.LIVE;
        case MediaType.REPLAY:
            return UTVPlayerStreamType.REPLAY;
        case MediaType.RECORDING:
            return UTVPlayerStreamType.RECORDING;
        case MediaType.VOD:
            return requestedStreamType;
        case MediaType.REGISTERED_TIMESHIFT:
            return UTVPlayerStreamType.TIMESHIFT;
        default:
            return requestedStreamType;
    }
};
const eventToPlayerEvent = (event) => {
    switch (event.type) {
        case EventKey.MEDIA_CHANGED:
        case EventKey.CURRENT_POSITION_CHANGED:
        case EventKey.PLAYER_STATE_CHANGED:
        case EventKey.PLAYBACK_STATE_CHANGED:
        case EventKey.AVAILABLE_AUDIO_TRACKS_CHANGED:
        case EventKey.AVAILABLE_SUBTITLES_TRACKS_CHANGED:
            return UTVPlayerEvents.STATE_UPDATED;
        case EventKey.SELECTED_AUDIO_TRACK_CHANGED:
        case EventKey.SELECTED_SUBTITLES_TRACK_CHANGED:
            return UTVPlayerEvents.TRACK_UPDATED;
        // eslint-disable-next-line no-fallthrough
        default:
            return UTVPlayerEvents.UNKNOWN;
    }
};
const OFF_SUBTITLE_TRACK = {
    id: 'off',
    locale: 'off',
    label: 'Off',
};
const PLAY_DEBOUNCE_MS = 500;
const PLAY_NEXT_PROGRAM_MS = 100;
const PREPARE_NEXT_PROGRAM_MS = 60_000;
const STATE_UPDATED_EVENT_THROTTLE_MS = 400;
const ZAPI_LAST_POSITION_UPDATE_THROTTLE_MS = 1000;
let debounceTimeout;
const debounce = (func, delay) => {
    return () => {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(func, delay);
    };
};
export const UTVPlayerView = forwardRef(({ onEvent, host, publicId, appVersion, appId, stepForwardDuration, stepBackwardDuration, locale, debug, style = styles.player, }, ref) => {
    const [sdkInitialized, setSdkInitialized] = useState(false);
    const playerInstanceRef = useRef(null);
    const playerStateRef = useRef(playerInitialState);
    const broadcastId = useRef(undefined);
    const vodTeasableId = useRef(undefined);
    const vodTeasableType = useRef(undefined);
    const programInfoRef = useRef(initialProgramInfo);
    const previousProgramInfoRef = useRef(initialProgramInfo);
    const languageNamesRef = useRef(undefined);
    const psid = useRef(undefined);
    const ignoreEventsWhileLoading = useRef(false);
    const pendingPlayCall = useRef(undefined);
    const resolveNewMediaTypeForPlayRequested = useRef(undefined);
    const playOptionQueue = useRef([]);
    const requestedOptions = useRef(undefined);
    const emittedCloseToEnd = useRef(false);
    const previousStream = useRef(undefined);
    const debugLogger = useCallback((error) => {
        if (debug) {
            console.error(error);
        }
    }, [debug]);
    const throttleStateUpdatedEvent = useMemo(() => onEvent && throttle(onEvent, STATE_UPDATED_EVENT_THROTTLE_MS), [onEvent]);
    const throttleCloseToEndEvent = useMemo(
    // There are some edge cases when system time is incorrect
    // we throttle CLOSE_TO_END event to avoid multiple events
    // @todo: Check if this is really needed
    () => onEvent && throttle(onEvent, 10_000), [onEvent]);
    const updateUTVPlayerState = useCallback((type, newState, data) => {
        if (type === UTVPlayerEvents.PLAY_REQUESTED) {
            // cancel previously queued STATE_UDATED event
            throttleStateUpdatedEvent?.cancel();
            previousStream.current = playerStateRef.current.stream;
        }
        const nextState = {
            ...playerStateRef.current,
            ...newState,
        };
        playerStateRef.current = nextState;
        if (!onEvent) {
            return;
        }
        const newEvent = {
            event: type,
            state: nextState,
        };
        if (data) {
            newEvent.data = data;
        }
        if (type === UTVPlayerEvents.STATE_UPDATED) {
            throttleStateUpdatedEvent?.(newEvent);
        }
        else if (type === UTVPlayerEvents.CLOSE_TO_END) {
            throttleCloseToEndEvent?.(newEvent);
        }
        else {
            onEvent(newEvent);
        }
    }, [onEvent, throttleStateUpdatedEvent, throttleCloseToEndEvent]);
    const triggerError = useCallback((type, data) => {
        const nextState = {
            ...playerStateRef.current,
            stream: playerStateRef.current.stream,
        };
        playerStateRef.current = nextState;
        onEvent?.({
            event: UTVPlayerEvents.ERROR,
            state: nextState,
            error: {
                errorType: type,
                errorData: data,
            },
        });
    }, [onEvent]);
    const playCall = useCallback(async (options) => {
        // Temporary solution to avoid raise condition
        await pendingPlayCall.current;
        switch (options?.streamType) {
            case UTVPlayerStreamType.VOD_MOVIE: {
                const { permissionToken, teasableId, ...playOptions } = options;
                return playerInstanceRef.current?.playVod(permissionToken, teasableId, Teasable.VOD_MOVIE, updateProgram(playOptions)).then(() => {
                    vodTeasableId.current = teasableId;
                    vodTeasableType.current = Teasable.VOD_MOVIE;
                }).catch(debugLogger);
            }
            case UTVPlayerStreamType.VOD_EPISODE: {
                const { permissionToken, teasableId, ...playOptions } = options;
                return playerInstanceRef.current?.playVod(permissionToken, teasableId, Teasable.VOD_SERIES_EPISODE, updateProgram(playOptions)).then(() => {
                    vodTeasableId.current = teasableId;
                    vodTeasableType.current = Teasable.VOD_SERIES_EPISODE;
                }).catch(debugLogger);
            }
            case UTVPlayerStreamType.LIVE: {
                const { channelId, ...playOptions } = options;
                return playerInstanceRef.current?.playLive(channelId, updateProgram(playOptions)).catch(debugLogger);
            }
            case UTVPlayerStreamType.REPLAY: {
                const { channelId, programId, ...playOptions } = options;
                return playerInstanceRef.current?.playProgram(channelId, programId, updateProgram(playOptions)).then(() => {
                    broadcastId.current = programId;
                }).catch(debugLogger);
            }
            case UTVPlayerStreamType.RECORDING: {
                const { programId, ...playOptions } = options;
                return playerInstanceRef.current?.playRecording(programId, updateProgram(playOptions)).then(() => {
                    broadcastId.current = programId;
                }).catch(debugLogger);
            }
            default: {
                return playerInstanceRef.current?.play();
            }
        }
    }, [debugLogger]);
    const callPlay = useCallback((options) => {
        pendingPlayCall.current = playCall(options)
            .then(() => {
            pendingPlayCall.current = undefined;
        });
    }, [playCall]);
    // Positive and negative numbers
    const seek = useCallback((numSteps) => {
        if (numSteps > 0) {
            playerInstanceRef.current?.seekForward(numSteps);
        }
        else if (numSteps < 0) {
            playerInstanceRef.current?.seekBackward(Math.abs(numSteps));
        }
    }, []);
    // Seek to position (relative program time ms)
    const seekTo = useCallback((positionMs) => {
        if (positionMs >= 0) {
            const newPositionMs = positionMs - (playerStateRef.current.streamStartMs ?? 0);
            playerInstanceRef.current?.seek(newPositionMs / 1000);
        }
    }, []);
    const isSameStream = useCallback((options) => {
        if (requestedOptions.current?.streamType !== options?.streamType
            || playerStateRef.current.stream?.streamType !== options?.streamType
            || options.pin) {
            return false;
        }
        switch (options?.streamType) {
            case UTVPlayerStreamType.VOD_MOVIE: {
                const { permissionToken, teasableId, } = requestedOptions.current;
                return (permissionToken === options.permissionToken
                    && teasableId === options.teasableId);
            }
            case UTVPlayerStreamType.VOD_EPISODE: {
                const { permissionToken, teasableId, } = requestedOptions.current;
                return (permissionToken === options.permissionToken
                    && teasableId === options.teasableId);
            }
            case UTVPlayerStreamType.LIVE: {
                const { channelId, } = requestedOptions.current;
                return channelId === options.channelId;
            }
            case UTVPlayerStreamType.REPLAY: {
                const { channelId, programId, } = requestedOptions.current;
                return (channelId === options.channelId
                    && programId === options.programId);
            }
            case UTVPlayerStreamType.RECORDING: {
                const { programId, } = requestedOptions.current;
                return programId === options.programId;
            }
            default: {
                return false;
            }
        }
    }, []);
    const play = useCallback((options, fromQueue, continuousStream) => {
        emittedCloseToEnd.current = false;
        if (!options) {
            if (playerStateRef.current.stream?.streamType === UTVPlayerStreamType.LIVE) {
                if (playerInstanceRef.current?.timeshiftAvailability === TimeshiftStatus.AVAILABLE) {
                    const waitForNewMediaType = new Promise((resolve) => {
                        resolveNewMediaTypeForPlayRequested.current = resolve;
                    });
                    // This looks like a hack to find out Registered Timeshift or Unregistered Timeshift
                    waitForNewMediaType.then((mediaType) => {
                        resolveNewMediaTypeForPlayRequested.current = undefined;
                        updateUTVPlayerState(UTVPlayerEvents.PLAY_REQUESTED, {
                            stream: {
                                ...playerStateRef.current.stream,
                                streamType: mediaType ?
                                    mapMediaTypeToStreamType(mediaType, UTVPlayerStreamType.LIVE) :
                                    UTVPlayerStreamType.REPLAY,
                            },
                        });
                    });
                }
            }
            playerInstanceRef.current?.play();
            return;
        }
        if (!fromQueue) {
            playOptionQueue.current = [];
        }
        updateUTVPlayerState(UTVPlayerEvents.PLAY_REQUESTED, {
            stream: {
                streamType: options.streamType,
                programDescriptor: options.programDescriptor,
            },
        });
        const sameStream = isSameStream(options);
        if (sameStream && options.startPositionMs !== undefined) {
            seekTo(options.startPositionMs);
            if (playerStateRef.current.paused) {
                playerInstanceRef.current?.play();
            }
        }
        previousProgramInfoRef.current = programInfoRef.current;
        programInfoRef.current.programStartEpochMs = options?.programStartEpochMs ?? 0;
        programInfoRef.current.programEndEpochMs = options?.programEndEpochMs ?? 0;
        programInfoRef.current.preMs = 0;
        programInfoRef.current.postMs = 0;
        programInfoRef.current.streamType = options?.streamType;
        if (!continuousStream && !sameStream) {
            ignoreEventsWhileLoading.current = true;
            psid.current = undefined;
            debounce(callPlay.bind(null, options), PLAY_DEBOUNCE_MS)();
            requestedOptions.current = options;
        }
    }, [updateUTVPlayerState, callPlay, isSameStream, seekTo]);
    const playNext = useCallback(() => {
        if (playOptionQueue.current.length > 0) {
            const nextOptions = playOptionQueue.current.shift();
            if (playerStateRef.current.stream?.streamType === UTVPlayerStreamType.LIVE
                || playerStateRef.current.stream?.streamType === UTVPlayerStreamType.TIMESHIFT) {
                // Avoid requesting new stream for LIVE and TIMESHIFT
                play(nextOptions, true, true);
            }
            else {
                play(nextOptions, true);
            }
        }
    }, [play]);
    useEffect(() => {
        if (!sdkInitialized) {
            playerInstanceRef.current?.destroy();
            playerInstanceRef.current = null;
            initPlaybackSdk().then(() => {
                setSdkInitialized(true);
            });
        }
    }, [sdkInitialized, setSdkInitialized]);
    useEffect(() => {
        if (!sdkInitialized) {
            return () => {
                playerInstanceRef.current?.destroy();
                playerInstanceRef.current = null;
            };
        }
        playerInstanceRef.current?.destroy();
        playerInstanceRef.current = null;
        programInfoRef.current = initialProgramInfo;
        let logging;
        if (debug) {
            const consoleLogger = new ConsoleLogger();
            const playerLogger = new ChannelLogger('PlaybackSDK::Player', consoleLogger);
            const adapterLogger = new ChannelLogger('PlaybackSDK::Adapter', consoleLogger);
            logging = {
                playerLogger,
                adapterLogger,
            };
        }
        playerInstanceRef.current = createPlayer({
            host,
            publicId,
            appVersion,
            appId,
            locale,
            stepBackwardDuration,
            stepForwardDuration,
            logging: !debug ? undefined : logging,
        });
        updateUTVPlayerState(UTVPlayerEvents.INITIALIZED);
        getLanguageNames().then((response) => {
            languageNamesRef.current = response;
        }).catch((error) => {
            triggerError(UTVPlayerErrorType.UNKNOWN, error);
        });
        const updateCurrentPosition = (event) => {
            let currentMs = event.position * 1000;
            const isAbsoluteTime = playerInstanceRef.current?.mediaType === MediaType.LIVE ||
                playerInstanceRef.current?.mediaType === MediaType.REGISTERED_TIMESHIFT;
            if (isAbsoluteTime) {
                currentMs -= programInfoRef.current.programStartEpochMs;
            }
            else {
                currentMs += playerStateRef.current.streamStartMs ?? 0;
            }
            const end = playerStateRef.current.programEndMs;
            if (end !== undefined
                && !emittedCloseToEnd.current
                && !ignoreEventsWhileLoading.current
                && pendingPlayCall.current === undefined
                && currentMs >= end - PREPARE_NEXT_PROGRAM_MS
                && currentMs < end - PLAY_NEXT_PROGRAM_MS) {
                updateUTVPlayerState(UTVPlayerEvents.CLOSE_TO_END);
                emittedCloseToEnd.current = true;
            }
            if (end !== undefined
                && playerStateRef.current.playing
                && pendingPlayCall.current === undefined
                && currentMs >= end - PLAY_NEXT_PROGRAM_MS) {
                playNext();
            }
            updateUTVPlayerState(eventToPlayerEvent(event), { currentMs });
        };
        const updatePlaybackState = (event) => {
            switch (event.state) {
                case PlaybackState.STOPPED:
                    if (!ignoreEventsWhileLoading.current) {
                        updateUTVPlayerState(UTVPlayerEvents.STOPPED, {
                            buffering: false,
                            canPlay: false,
                            canPause: false,
                            paused: false,
                            playing: false,
                            stopped: true,
                        });
                    }
                    break;
                case PlaybackState.PLAYING: {
                    ignoreEventsWhileLoading.current = false;
                    const updateState = {
                        buffering: false,
                        canPlay: false,
                        paused: false,
                        playing: true,
                        stopped: false,
                    };
                    if (resolveNewMediaTypeForPlayRequested.current) {
                        resolveNewMediaTypeForPlayRequested.current(null);
                        Promise.resolve().then(() => {
                            updateUTVPlayerState(UTVPlayerEvents.PLAYING, updateState);
                        });
                    }
                    else {
                        updateUTVPlayerState(UTVPlayerEvents.PLAYING, updateState);
                    }
                    break;
                }
                case PlaybackState.PAUSED:
                    if (!ignoreEventsWhileLoading.current) {
                        updateUTVPlayerState(UTVPlayerEvents.PAUSED, {
                            canPlay: true,
                            canPause: true,
                            paused: true,
                            playing: false,
                            stopped: false,
                        });
                    }
                    break;
                case PlaybackState.BUFFERING:
                    updateUTVPlayerState(UTVPlayerEvents.STATE_UPDATED, {
                        buffering: true,
                    });
                    break;
                default:
                    break;
            }
        };
        const updatePlayerState = (event) => {
            const newState = {
                canForward: event.canSeekForward,
                canBackward: event.canSeekBackward,
                canPause: event.canPause,
                // @todo: Check if there is edge case
                canPlayFromStart: true,
            };
            if (event.seekableRange) {
                const startMs = event.seekableRange.start * 1000;
                const endMs = event.seekableRange.end * 1000;
                const isAbsoluteTime = playerInstanceRef.current?.mediaType === MediaType.LIVE ||
                    playerInstanceRef.current?.mediaType === MediaType.REGISTERED_TIMESHIFT;
                const startRelativeTimeMs = isAbsoluteTime
                    ? startMs - programInfoRef.current.programStartEpochMs
                    : startMs;
                const endRelativeTimeMs = isAbsoluteTime
                    ? endMs - programInfoRef.current.programStartEpochMs
                    : endMs;
                newState.streamStartMs = Math.max(startRelativeTimeMs - programInfoRef.current.preMs, 
                // User doesn't care about the seekable range before pre-padding
                -programInfoRef.current.preMs);
                newState.streamEndMs = endRelativeTimeMs - programInfoRef.current.preMs;
                newState.programStartMs = 0;
                newState.programEndMs = playerInstanceRef.current?.mediaType === MediaType.VOD
                    ? newState.streamEndMs
                    : programInfoRef.current.programEndEpochMs - programInfoRef.current.programStartEpochMs;
            }
            updateUTVPlayerState(eventToPlayerEvent(event), newState);
        };
        const updateAudioTracks = (event) => {
            updateUTVPlayerState(eventToPlayerEvent(event), {
                audioTracks: event.tracks.map((track) => ({
                    ...track,
                    label: getISOLanguageName(track, languageNamesRef.current, locale),
                })),
            });
        };
        const updateSubtitlesTracks = (event) => {
            const mappedSubtitleTracks = event.tracks.map((track) => ({
                ...track,
                label: getISOLanguageName(track, languageNamesRef.current, locale),
            }));
            mappedSubtitleTracks.push(OFF_SUBTITLE_TRACK);
            updateUTVPlayerState(eventToPlayerEvent(event), {
                subtitleTracks: mappedSubtitleTracks,
                currentSubtitleTrack: OFF_SUBTITLE_TRACK,
            });
        };
        const updateSelectedAudioTrack = (event) => {
            if (!event.targetTrack) {
                updateUTVPlayerState(eventToPlayerEvent(event), {
                    currentAudioTrack: undefined,
                });
                return;
            }
            updateUTVPlayerState(eventToPlayerEvent(event), {
                currentAudioTrack: {
                    ...event.targetTrack,
                    label: getISOLanguageName(event.targetTrack, languageNamesRef.current),
                },
            });
        };
        const updateSelectedSubtitleTrack = (event) => {
            if (!event.targetTrack) {
                updateUTVPlayerState(eventToPlayerEvent(event), {
                    currentSubtitleTrack: OFF_SUBTITLE_TRACK,
                });
                return;
            }
            updateUTVPlayerState(eventToPlayerEvent(event), {
                currentSubtitleTrack: {
                    ...event.targetTrack,
                    label: getISOLanguageName(event.targetTrack, languageNamesRef.current),
                },
            });
        };
        const updateMedia = (event) => {
            if (playerInstanceRef.current &&
                resolveNewMediaTypeForPlayRequested.current) {
                resolveNewMediaTypeForPlayRequested.current(playerInstanceRef.current.mediaType);
            }
            const { mediaType } = event.media;
            programInfoRef.current.preMs = event.media.prePadding * 1000;
            programInfoRef.current.postMs = event.media.postPadding * 1000;
            const playerDuration = playerInstanceRef.current?.duration ?? 0;
            const durationMs = isFinite(playerDuration) ? playerDuration * 1000 : 0;
            const newState = {
                canPlayLive: mediaType === MediaType.REPLAY || mediaType === MediaType.REGISTERED_TIMESHIFT,
                programStartMs: 0,
                programEndMs: mediaType === MediaType.VOD
                    ? durationMs - programInfoRef.current.preMs
                    : programInfoRef.current.programEndEpochMs - programInfoRef.current.programStartEpochMs,
                streamStartMs: 0 - programInfoRef.current.preMs,
                streamEndMs: durationMs - programInfoRef.current.preMs,
            };
            if (playerStateRef.current.stream) {
                newState.stream = {
                    ...playerStateRef.current.stream,
                    streamType: mapMediaTypeToStreamType(mediaType, playerStateRef.current.stream.streamType),
                };
            }
            // Magic to queue this event behind PLAY_REQUESTED
            Promise.resolve().then(() => {
                updateUTVPlayerState(eventToPlayerEvent(event), newState);
            });
        };
        const watchReceivedListener = (event) => {
            if (event.data.drm_limit_applied) {
                updateUTVPlayerState(UTVPlayerEvents.QUALITY_RESTRICTED, {}, {
                    quality: event.data.stream.quality,
                });
            }
        };
        const updateError = (event) => {
            const error = event.error;
            const isZapiPinError = isZAPIError(error) && (error.code === ZAPIErrorCodes.PIN_MISSING ||
                error.code === ZAPIErrorCodes.PIN_INVALID ||
                error.code === ZAPIErrorCodes.PIN_SETUP_REQUIRED ||
                error.code === ZAPIErrorCodes.PIN_LOCKED);
            const isPlaybackPinError = isPlaybackError(error) && (error.code === ErrorCode.PinRequired);
            if (isZapiPinError || isPlaybackPinError) {
                return triggerError(UTVPlayerErrorType.PIN_ERROR, {
                    code: error?.code,
                    data: error,
                });
            }
            if (error.code === ErrorCode.Source || isZAPIError(error)) {
                return triggerError(UTVPlayerErrorType.STREAM_CANNOT_PLAY, {
                    error,
                });
            }
            if (error.code === ErrorCode.ForbiddenAction) {
                return triggerError(UTVPlayerErrorType.FORBIDDEN_ACTION, {
                    error,
                });
            }
            return triggerError(UTVPlayerErrorType.UNKNOWN, {
                error,
            });
        };
        const filterOldEvents = (listener) => {
            const newListener = (event) => {
                if (event.psid !== psid.current) {
                    return;
                }
                listener(event);
            };
            return newListener;
        };
        const updateWatchRequested = (event) => {
            psid.current = event.psid;
        };
        const updateLastPlayedPosition = (programStartEpochMs, stream) => {
            if (playerInstanceRef.current?.mediaType === MediaType.VOD) {
                if (playerStateRef.current.currentMs !== undefined
                    && vodTeasableId.current
                    && vodTeasableType.current) {
                    updateVODStatus({
                        teasable_id: vodTeasableId.current,
                        teasable_type: vodTeasableType.current,
                        position: Math.floor(playerStateRef.current.currentMs / 1000),
                    }).then((response) => {
                        updateUTVPlayerState(UTVPlayerEvents.POSITION_SAVED, {}, {
                            stream,
                            response,
                        });
                    });
                }
                return;
            }
            if (playerStateRef.current.currentMs !== undefined && broadcastId.current) {
                updateBroadcastPosition({
                    broadcast_id: broadcastId.current,
                    position: (programStartEpochMs + playerStateRef.current.currentMs) / 1000,
                }).then((response) => {
                    updateUTVPlayerState(UTVPlayerEvents.POSITION_SAVED, {}, {
                        stream,
                        response,
                    });
                });
            }
        };
        const throttleLastUpdatedPosition = throttle(updateLastPlayedPosition, ZAPI_LAST_POSITION_UPDATE_THROTTLE_MS);
        playerInstanceRef.current?.on(EventKey.WATCH_REQUESTED, updateWatchRequested);
        playerInstanceRef.current?.on(EventKey.PLAYBACK_STATE_CHANGED, (event) => {
            if (event.state === PlaybackState.STOPPED) {
                updateLastPlayedPosition(previousProgramInfoRef.current.programStartEpochMs, previousStream.current);
            }
            else if (event.state === PlaybackState.PAUSED) {
                throttleLastUpdatedPosition(programInfoRef.current.programStartEpochMs, playerStateRef.current.stream);
            }
            filterOldEvents(updatePlaybackState)(event);
        });
        const listenCurrentStreamEvent = (eventKey, listener) => {
            playerInstanceRef.current?.on(eventKey, filterOldEvents(listener));
        };
        const checkCloseToEnd = (event) => {
            let currentMs = event.position * 1000;
            const isAbsoluteTime = playerInstanceRef.current?.mediaType === MediaType.LIVE ||
                playerInstanceRef.current?.mediaType === MediaType.REGISTERED_TIMESHIFT;
            if (isAbsoluteTime) {
                currentMs -= programInfoRef.current.programStartEpochMs;
            }
            else {
                currentMs += playerStateRef.current.streamStartMs ?? 0;
            }
            const end = playerStateRef.current.programEndMs;
            if (end !== undefined
                && !emittedCloseToEnd.current
                && !ignoreEventsWhileLoading.current
                && pendingPlayCall.current === undefined
                && currentMs >= end - PREPARE_NEXT_PROGRAM_MS) {
                updateUTVPlayerState(UTVPlayerEvents.CLOSE_TO_END);
                emittedCloseToEnd.current = true;
            }
            if (end !== undefined
                && playerStateRef.current.playing
                && pendingPlayCall.current === undefined
                && currentMs >= end - PLAY_NEXT_PROGRAM_MS) {
                playNext();
            }
        };
        listenCurrentStreamEvent(EventKey.SEEKED, checkCloseToEnd);
        listenCurrentStreamEvent(EventKey.CURRENT_POSITION_CHANGED, updateCurrentPosition);
        listenCurrentStreamEvent(EventKey.PLAYER_STATE_CHANGED, updatePlayerState);
        listenCurrentStreamEvent(EventKey.AVAILABLE_AUDIO_TRACKS_CHANGED, updateAudioTracks);
        listenCurrentStreamEvent(EventKey.AVAILABLE_SUBTITLES_TRACKS_CHANGED, updateSubtitlesTracks);
        listenCurrentStreamEvent(EventKey.SELECTED_AUDIO_TRACK_CHANGED, updateSelectedAudioTrack);
        listenCurrentStreamEvent(EventKey.SELECTED_SUBTITLES_TRACK_CHANGED, updateSelectedSubtitleTrack);
        listenCurrentStreamEvent(EventKey.MEDIA_CHANGED, updateMedia);
        listenCurrentStreamEvent(EventKey.PLAYER_ERROR, updateError);
        listenCurrentStreamEvent(EventKey.WATCH_RECEIVED, watchReceivedListener);
        return () => {
            updateLastPlayedPosition(programInfoRef.current.programStartEpochMs, playerStateRef.current.stream);
            playerInstanceRef.current?.destroy();
        };
    }, [
        appId, appVersion, host, onEvent, publicId, stepBackwardDuration,
        stepForwardDuration, updateUTVPlayerState, triggerError, locale,
        playNext, debug, sdkInitialized,
    ]);
    // const playFromStart = useCallback((params) => {
    // }, []);
    const setAudioTrack = useCallback((id) => {
        setMediaTrackById(id, playerStateRef.current.audioTracks, playerInstanceRef.current?.setAudioTrack.bind(playerInstanceRef.current));
    }, []);
    const setSubtitleTrack = useCallback((id) => {
        if (id === 'off') {
            playerInstanceRef.current?.setSubtitlesTrack(null);
            return;
        }
        setMediaTrackById(id, playerStateRef.current.subtitleTracks, playerInstanceRef.current?.setSubtitlesTrack.bind(playerInstanceRef.current));
    }, []);
    const cuePlay = useCallback((options) => {
        playOptionQueue.current = [options];
    }, []);
    const playPause = useCallback(() => {
        // @todo Check what do we do when the player is buffering
        if (playerInstanceRef.current?.currentState === PlaybackState.PLAYING) {
            playerInstanceRef.current?.pause();
        }
        else if (playerInstanceRef.current?.currentState === PlaybackState.PAUSED) {
            play();
        }
    }, [play]);
    const pause = useCallback(() => {
        playerInstanceRef.current?.pause();
    }, []);
    const stop = useCallback(() => {
        playerInstanceRef.current?.stop();
    }, []);
    useImperativeHandle(ref, () => ({
        stop,
        play,
        pause,
        playPause,
        seek,
        seekTo,
        setAudioTrack,
        setSubtitleTrack,
        cuePlay,
    }), [stop, play, seek, seekTo, setAudioTrack, setSubtitleTrack, pause, playPause, cuePlay]);
    if (!playerInstanceRef.current) {
        return null;
    }
    return (React.createElement(PlayerView, { player: playerInstanceRef.current, style: style }));
});
